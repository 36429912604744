import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

const sortByStenoOrder = chord => `
    ${chord.includes("topLeftS") || chord.includes("bottomLeftS") ? "S" : ""}
    ${chord.includes("leftT") ? "T" : ""}
    ${chord.includes("k") ? "K" : ""}
    ${chord.includes("leftP") ? "P" : ""}
    ${chord.includes("w") ? "W" : ""}
    ${chord.includes("h") ? "H" : ""}
    ${chord.includes("leftR") ? "R" : ""}
    ${chord.includes("a") ? "A" : ""}
    ${chord.includes("o") ? "O" : ""}
    ${chord.includes("bottomAsterisk") || chord.includes("topAsterisk") ? "*" : ""}
    ${chord.includes("e") ? "E" : ""}
    ${chord.includes("u") ? "U" : ""}
    ${chord.includes("f") ? "F" : ""}
    ${chord.includes("rightR") ? "R" : ""}
    ${chord.includes("rightP") ? "P" : ""}
    ${chord.includes("b") ? "B" : ""}
    ${chord.includes("l") ? "L" : ""}
    ${chord.includes("g") ? "G" : ""}
    ${chord.includes("rightT") ? "T" : ""}
    ${chord.includes("rightS") ? "S" : ""}
    ${chord.includes("d") ? "D" : ""}
    ${chord.includes("z") ? "Z" : ""}
`.replace(/\s/g, "")

const removeFromArray = (array, value) => array.filter(ele => ele != value)

const StenoButton = props => {
  const {
    key,
    id,
    label,
    isRounded,
    className,
    currentChord,
    setCurrentChord,
    numbersOfButtonsTouched,
    setNumberOfButtonsTouched,
  } = props

  return (
    <button
      onTouchStart={event => {
        setCurrentChord([...currentChord, id])
        setNumberOfButtonsTouched(numbersOfButtonsTouched + 1)
      }}
      onTouchEnd={event => {
        setCurrentChord(removeFromArray(currentChord, id))
        if (numbersOfButtonsTouched > 0) {
          setNumberOfButtonsTouched(numbersOfButtonsTouched - 1)
        }
      }}
      key={key}
      className={`w-full p-4 h-full border-2 max-w-8 ${
        isRounded ? "rounded-b-lg" : ""
      } border-gray-900 hover:bg-gray-200 ${className}`}
    >
      {label}
    </button>
  )
}

const StenoBoard = props => {
  const [currentChord, setCurrentChord] = useState([])
  const [numberOfButtonsTouched, setNumberOfButtonsTouched] = useState(0)

  useEffect(() => {
    if (numberOfButtonsTouched === 0) {
      setCurrentChord([])
    }
  }, [numberOfButtonsTouched])

  return (
    <div className="w-screen h-screen grid grid-cols-10">
      <div className="col-span-full flex items-center justify-center h-full border-2 rounded-b-lg border-gray-900 hover:bg-gray-200">
        <p>#</p>
      </div>
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        className=""
        currentChord={currentChord}
        id="topLeftS"
        label="S"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="leftT"
        label="T"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="leftP"
        label="P"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="h"
        label="H"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="topAsterisk"
        label="*"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="f"
        label="F"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="rightP"
        label="P"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="l"
        label="L"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="rightT"
        label="T"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="d"
        label="D"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="bottomLeftS"
        label="S"
        isRounded="true"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="k"
        label="K"
        isRounded="true"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="w"
        label="W"
        isRounded="true"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="leftR"
        label="R"
        isRounded="true"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="bottomAsterisk"
        label="*"
        isRounded="true"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="rightR"
        label="R"
        isRounded="true"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="b"
        label="B"
        isRounded="true"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="g"
        label="G"
        isRounded="true"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="rightS"
        label="S"
        isRounded="true"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        id="z"
        label="Z"
        isRounded="true"
      />
      <div className="col-start-2">{numberOfButtonsTouched}</div>
      <StenoButton
        {...props}
        currentChord={currentChord}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        className="col-start-3 ml-10"
        id="a"
        label="A"
        isRounded="true"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        className="ml-10"
        id="o"
        label="O"
        isRounded="true"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        className="ml-20"
        id="e"
        label="E"
        isRounded="true"
      />
      <StenoButton
        {...props}
        setNumberOfButtonsTouched={setNumberOfButtonsTouched}
        numbersOfButtonsTouched={numberOfButtonsTouched}
        setCurrentChord={setCurrentChord}
        currentChord={currentChord}
        className="ml-20"
        id="u"
        label="U"
        isRounded="true"
      />
      <textarea className="col-start-8 col-span-3" value={sortByStenoOrder(currentChord)} />
    </div>
  )
}

export default StenoBoard

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
